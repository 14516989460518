<template>
  <div>
    <my-nav-bar
      title="收款明细"
      left-text="返回"
      left-arrow
    />
    <van-list
      v-model="loading"
      class="list"
      :finished="finished"
      @load="getList"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="unpaidReceive in list" :key="unpaidReceive.id" class="cell">
        <van-cell title="客户名称" :value="unpaidReceive.client.name" />
        <van-cell title="单据编号" :value="unpaidReceive.order ? unpaidReceive.order.num : unpaidReceive.after_order.num" />
        <van-cell value-class="cell-amount" title="收款金额" :value="'￥' + unpaidReceive.receive_amount" />
        <van-cell title="收款日期" :value="unpaidReceive.receive_date" />
        <van-cell title="收款人" :value="unpaidReceive.employee.name" />
      </div>
    </van-list>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClientUnpaidReceiveDetail } from '@/api/client-unpaid'
export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0,
        limit: 10,
        client_id: this.$route.query.client_id
      },
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getClientUnpaidReceiveDetail(this.listQuery).then(res => {
        this.loading = false
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .list {
    margin-top: 55px;
  }
  .cell {
    margin: 0 10px 10px 10px;
  }
  .cell-amount {
    color: $amountRedColor;
  }
</style>
