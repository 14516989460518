var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "收款明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (unpaidReceive) {
    return _c('div', {
      key: unpaidReceive.id,
      staticClass: "cell"
    }, [_c('van-cell', {
      attrs: {
        "title": "客户名称",
        "value": unpaidReceive.client.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "单据编号",
        "value": unpaidReceive.order ? unpaidReceive.order.num : unpaidReceive.after_order.num
      }
    }), _c('van-cell', {
      attrs: {
        "value-class": "cell-amount",
        "title": "收款金额",
        "value": '￥' + unpaidReceive.receive_amount
      }
    }), _c('van-cell', {
      attrs: {
        "title": "收款日期",
        "value": unpaidReceive.receive_date
      }
    }), _c('van-cell', {
      attrs: {
        "title": "收款人",
        "value": unpaidReceive.employee.name
      }
    })], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }